import * as React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import Layout from "@components/Layout";
import ComingSoon from "@components/ComingSoon";
import "./index.sass"

const ComingSoonPage = () => (
  <Layout isHeaderFixed isFooterFixed>
    <div className="coming-soon-page" >
      <div className="coming-soon-icon">
        <ComingSoon />
      </div>
      <div className="coming-soon-text">
        <h1 className="coming-soon-title">
            <FormattedMessage id="ComingSoonPage_TITLE" />
        </h1>
        <span className="coming-soon-body">
          <FormattedMessage id="ComingSoonPage_CAPTION" />
        </span>
        <Link to="/" className="coming-soon-home">
          <FormattedMessage id="ComingSoonPage_CAPTION_HOME" />
        </Link>
      </div>
    </div>
  </Layout>
);

export default ComingSoonPage;
